import { Box, Container, Divider, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import MinusIcon from 'src/icons/Minus';
import parseUrl from 'src/utils/urlParser';
import LogoApp from 'src/components/LogoApp';
import useAuth from 'src/hooks/useAuth';


const environment = parseUrl();

const Footer = (props) => {
  const { permissions } = useAuth().user;

  const sections = [
    {
      title: 'Menu',
      links: [
        ...(permissions.show_prices_search) ? [{
          title: 'Buscador de precios',
          href: '/'
        }] : [],
        {
          title: 'Informes',
          href: '/reports'
        },
        {
          title: 'Historial',
          href: '/historical_data'
        }
      ]
    }
  ];

  return (
    <Box
      sx={{
      backgroundColor: 'background.default',
      pb: 6,
      pt: {
        md: 5
      }
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid
          item
          md={3}
          sm={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            order: {
              md: 1,
              xs: 4
            }
          }}
          xs={12}
        >
          <LogoApp img="logo.png" height={environment === 'cavem' ? 100 : 90} width={environment === 'cavem' ? 100 : 180} />
        </Grid>
        {sections.map((section, index) => (
          <Grid
            item
            key={section.title}
            md={3}
            sm={4}
            sx={{
              order: {
                md: index + 2,
                xs: index + 1
              }
            }}
            xs={12}
          >
            <Typography color="textSecondary" variant="overline">
              {section.title}
            </Typography>
            <List disablePadding>
              {section.links.map((link) => (
                <ListItem
                  disableGutters
                  key={link.title}
                  sx={{
                    pb: 0,
                    pt: 1
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      minWidth: 0,
                      mr: 0.5
                    }}
                  >
                    <MinusIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link href={link.href} color="textPrimary" variant="subtitle2">
                        {link.title}
                      </Link>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
          my: 2
        }}
      />
      <Typography color="textSecondary" variant="caption">
        Todos los derechos reservados.
      </Typography>
    </Container>
  </Box>
  );
};

export default Footer;
