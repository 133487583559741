import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Divider, Hidden, Link, Toolbar } from '@material-ui/core';
import parseUrl from 'src/utils/urlParser';
import LogoApp from 'src/components/LogoApp';
import AccountPopover from './Account';
import useAuth from 'src/hooks/useAuth';

const MainNavbar = (props) => {
  const environment = parseUrl();
  const { permissions } = useAuth().user;

  return (
    <AppBar
      elevation={2}
      sx={{
        backgroundColor: 'background.default',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden mdDown>
          <Box pl={20}>
            <RouterLink to="/">
              <LogoApp img="logo2.png" height={environment === 'cavem' ? 40 : 50} width={100} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden mdDown>
          <Box
            hidden={!permissions.show_prices_search}
            sx={{
              mx: 2
            }}
          >
            <Link color="textPrimary" component={RouterLink} to="/" underline="none" variant="button">
              BUSCADOR DE PRECIOS
            </Link>
          </Box>
          <Box
            sx={{
              mx: 2
            }}
          >
            <Link color="textPrimary" component={RouterLink} to="/reports" underline="none" variant="button">
              INFORMES
            </Link>
          </Box>
          <Box
            sx={{
              mx: 2
            }}
          >
            <Link color="textPrimary" component={RouterLink} to="/historical_data" underline="none" variant="button">
              HISTORIAL
            </Link>
          </Box>

          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mr: 2,
              ml: 10
            }}
          />
        </Hidden>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
